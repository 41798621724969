<template>
    <PublicLayout :title="$t('Home')">
        <template #title>
            <div></div>
        </template>
        <template #sharing>
            <div></div>
        </template>
        <template #header>
            <AboutUsBlock />
        </template>
        <template #breadcrumbs>
            <div />
        </template>
        <main class="flex flex-col">
            <VideoPlayer v-if="!fullScreenVideo || true" type="preview" :link="videoUrl" class="mt-10" />
            <SectionHeader :text="$t('frontend.products')" />
            <CategoriesBlock :categories="categories" />
            <!-- <div class="my-6">
                <ContactForm :legal-info="legalInfo" :social-info="socialInfo"/>
            </div> -->
        </main>
    </PublicLayout>
</template>

<script setup>
// import {onMounted} from 'vue';
// import Rellax from 'rellax';
import VideoPlayer from '@/Components/Media/VideoPlayer.vue';
import {SectionHeader} from '@/Components/Typography';
import PublicLayout from '@/Layouts/PublicLayout.vue';
import AboutUsBlock from '@/Pages/Frontend/Partials/AboutUsBlock.vue';
import CategoriesBlock from '@/Pages/Frontend/Partials/CategoriesBlock.vue';

// import ContactForm from '@/Pages/Frontend/Partials/ContactForm.vue';

defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    hasTermsAndPrivacy: Boolean,
    laravelVersion: String,
    phpVersion: String,
    legalInfo: {
        type: Object,
        required: true,
    },
    socialInfo: {
        type: Object,
        required: true,
    },
    fullScreenVideo: {
        type: Boolean,
        default: true,
    },
    categories: {
        type: Array,
        required: true,
    },
});

// const carouselImages = [CarouselImage1, CarouselImage2, CarouselImage3, CarouselImage4, CarouselImage5];

const videoUrl =
    // 'https://fast.wistia.com/embed/medias/tdcunlkuiv.m3u8';
    // 'https://sample.vodobox.net/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8';
    // 'https://playertest.longtailvideo.com/adaptive/wowzaid3/playlist.m3u8';
    'https://assets.afcdn.com/video49/20210722/v_645516.m3u8';

/* onMounted(() => {
    new Rellax('.rellax');
}); */
</script>
